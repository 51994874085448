import { Injectable, Injector } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, IdTokenClaims } from '@azure/msal-browser';
import { Claim } from '@clinician/common/enums/claim';
import { environment } from '@clinician_environment';
import { BehaviorSubject } from 'rxjs';

interface PermissionClaims extends IdTokenClaims {
  extension_ClinicGroupId?: string;
  extension_ClinicReportPermission?: number;
  extension_PatientDetailsPermission?: number;
  extension_PatientReportPermission?: number;
  extension_TreatmentSettingsPermission?: number;
  extension_IsAdmin?: boolean;
  extension_Title?: string;
  given_name?: string;
  family_name?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  patientDetails: BehaviorSubject<Claim> = new BehaviorSubject<Claim>(
    Claim.NoAccess,
  );

  patientReports: BehaviorSubject<Claim> = new BehaviorSubject<Claim>(
    Claim.NoAccess,
  );

  treatmentSettings: BehaviorSubject<Claim> = new BehaviorSubject<Claim>(
    Claim.NoAccess,
  );

  clinicReport: BehaviorSubject<Claim> = new BehaviorSubject<Claim>(
    Claim.NoAccess,
  );

  adminStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  clinicGroupId$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);

  username: string[];
  authService: MsalService;
  loggedAccountId: string;

  constructor(readonly injector: Injector) {
    if (environment.b2cTenant.enabled) {
      this.authService = this.injector.get(MsalService);
      this.refreshClaims();
    }
  }

  refreshClaims(): void {
    const authInstance = this.authService.instance.getAllAccounts()[0];
    if (authInstance?.idTokenClaims !== undefined) {
      const claims: PermissionClaims = authInstance.idTokenClaims;
      this.loggedAccountId = authInstance.localAccountId;

      this.clinicReport.next(
        (claims?.extension_ClinicReportPermission as number) ?? Claim.NoAccess,
      );
      this.patientDetails.next(
        (claims?.extension_PatientDetailsPermission as number) ??
          Claim.NoAccess,
      );
      this.patientReports.next(
        (claims?.extension_PatientReportPermission as number) ?? Claim.NoAccess,
      );
      this.treatmentSettings.next(
        (claims?.extension_TreatmentSettingsPermission as number) ??
          Claim.NoAccess,
      );
      this.adminStatus.next((claims?.extension_IsAdmin as boolean) ?? false);
      this.clinicGroupId$.next(claims?.extension_ClinicGroupId);
      this.username = this.buildUsername(authInstance);
    }
  }

  buildUsername(authInstance: AccountInfo): string[] {
    const claims: PermissionClaims | undefined = authInstance.idTokenClaims;
    const title = claims?.extension_Title || '';
    return [
      title as string,
      `${claims?.given_name as string} ${claims?.family_name as string}`,
    ];
  }
}
