import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Claim } from '@clinician/common/enums/claim';
import { notificationMessages } from '@clinician/common/notification-names/notification-messages';
import { PermissionsService } from '@clinician/services/claims/permissions.service';
import { NcSnackBarService } from '@neurocare/ui';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CanActivateClinicReport {
  constructor(
    readonly claimsService: PermissionsService,
    readonly snackBarService: NcSnackBarService,
  ) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const hasAccess = this.claimsService.clinicReport.value !== Claim.NoAccess;
    if (!hasAccess) {
      this.snackBarService.openSnackBar(
        notificationMessages.messageClinicReportPermission,
        'error',
      );
    }
    return hasAccess;
  }
}
