/*eslint-disable @typescript-eslint/no-explicit-any*/
import { NcSnackBarService } from '@neurocare/ui';
import { EMPTY, Observable, ObservableInput, of, OperatorFunction } from 'rxjs';
import { catchError } from 'rxjs/operators';

interface DefaultCatchErrorOptions<T> {
  fallbackErrorMessage?: string;
  customHandler?: (error: any, caught: Observable<T>) => void;
  returnData?: T | (() => T);
}

export const defaultCatchError = <T>(
  snackBarService: NcSnackBarService,
  options: DefaultCatchErrorOptions<T> = {},
): OperatorFunction<T, T> => {
  return (source: Observable<T>) =>
    source.pipe(
      catchError<T, ObservableInput<T>>((error, caught) => {
        const message =
          error.error?.detail ||
          error.error?.title ||
          options?.fallbackErrorMessage ||
          'Internal server error';

        snackBarService.openSnackBar(message, 'error');

        if (options?.customHandler) {
          options.customHandler(error, caught);
        }

        if (options.returnData === undefined) {
          return EMPTY;
        }

        if (typeof options.returnData === 'function') {
          const returnData = (options.returnData as () => T)();
          return of(returnData);
        }

        return of(options.returnData);
      }),
    );
};
