<div class="flex h-screen flex-col items-center justify-between">
  <img src="/assets/logos/image-logo.svg" alt="Neurocare Logo" class="w-full" />
  <div class="flex w-full flex-col gap-6">
    <button
      nc-nav-button
      [icon]="NcIcon.Patient"
      routerLink="patient"
      routerLinkActive="active_link"
    >
      Patients
    </button>
    @if (
      showClinicReportTab && (this.featureFlagService.getFeature('tms') | async)
    ) {
      <button
        nc-nav-button
        [icon]="NcIcon.File"
        routerLink="clinic-report"
        routerLinkActive="active_link"
      >
        Clinic Report
      </button>
    }
    <button
      nc-nav-button
      [icon]="NcIcon.Settings"
      routerLink="setting"
      routerLinkActive="active_link"
    >
      Settings
    </button>
  </div>
  <div class="mb-4 w-full">
    <button nc-nav-button [icon]="NcIcon.Logout1" (click)="logout()">
      Logout
    </button>
  </div>
</div>
