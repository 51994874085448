export const environment = {
  apiURL: 'https://backend-cab29c74660a-d3e4g3f0cfg9f9fa.a01.azurefd.net',
  patientCompanionURL: 'https://dev.patient.neurocaregroup.com',
  production: true,
  hideClinicReports: false,
  b2cTenant: {
    enabled: true,
    authorityDomain: 'neurocaredtpsdev.b2clogin.com',
    signInFlowName: 'B2C_1_SignIn',
    signInAuthority:
      'https://neurocaredtpsdev.b2clogin.com/neurocaredtpsdev.onmicrosoft.com/B2C_1_SignIn',
    clientId: '514443ab-f1d3-4b01-9ab3-5f36bf937d11',
    scopes: [
      'https://neurocaredtpsdev.onmicrosoft.com/dtps.backend.api/clinician',
    ],
  },
  patientPdfLinkText: 'dev.patient.neurocaregroup.com/signup',
  appInsights: {
    instrumentationKey: '6f57cc2b-7077-4a24-87ad-4b0a619a5121',
  },
  useAppInsights: true,
  featureFlagsURL:
    'https://st7b2cscreenscab29c74660.blob.core.windows.net/feature-flags',
  showFeatureFlagToggle: true,
};
