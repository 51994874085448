import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Claim } from '@clinician/common/enums/claim';
import { PermissionsService } from '@clinician/services/claims/permissions.service';
import { FeatureFlagService } from '@clinician/services/core/feature-flag.service';
import { environment } from '@clinician_environment';
import { NcIcon } from '@neurocare/ui';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-navigation-panel',
  templateUrl: './navigation-panel.component.html',
})
export class NavigationPanelComponent implements OnInit, OnDestroy {
  authService: MsalService;
  showButtons: boolean;
  showClinicReportTab: boolean;
  subs: Subscription[] = [];

  NcIcon = NcIcon;

  constructor(
    readonly permissionsService: PermissionsService,
    readonly featureFlagService: FeatureFlagService,
    readonly injector: Injector,
  ) {
    if (environment.b2cTenant.enabled) {
      this.authService = this.injector.get(MsalService);
    }
  }

  logout(): void {
    if (environment.b2cTenant.enabled) {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: '/patient',
      });
    }
  }

  ngOnInit(): void {
    const clinicReportPermissionSub =
      this.permissionsService.clinicReport.subscribe({
        next: (claim) => {
          this.showClinicReportTab = claim !== Claim.NoAccess;
        },
      });

    this.subs.push(clinicReportPermissionSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
