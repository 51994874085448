export const notificationMessages = {
  messageSuccessDeviceActivate: 'Device successfully activated',
  messageSuccessProtocolActivate: 'Protocol successfully activated',
  messageSuccessDeviceDeactivate: 'Device successfully deactivated',
  messageSuccessProtocolDeactivate: 'Protocol successfully deactivated',
  messageSuccessDeviceAdded: 'Device successfully added',
  messageSuccessDeviceEdited: 'Device successfully edited',
  messageSuccessPrescriptionRemoved: 'Prescription was removed successfully',
  internalServerError: 'Internal Server Error',
  messageSuccessPatientDeleted: 'Patient successfully deactivated',
  messageSuccessPatientActivate: 'Patient successfully activated',
  confirmContentMessage: "This can't be undone.",
  messageSetupPatientBefore:
    'Please setup the patient before treatment assignment',
  messageProtocolSuccess: 'Protocol was successfully added',
  messageSuccessEdited: 'Patient successfully edited',
  messageAccountNotFound: 'Account was not found',
  messageAccountEditError: 'Account could not be edited',
  messageAccountEditedSuccessfully: 'Account was successfully edited',
  messagePasswordChangeSuccess: 'Password was successfully edited',
  messagePasswordChangeError: 'Password could not be changed',
  messageTreatmentSettingsPermission:
    'You do not have the permission to view Treatment Settings',
  messageClinicReportPermission:
    'You do not have the permission to view Clinic Report',
  messageCannotEditThisAccount: 'You can only edit your account',
  messageSuccessAccountDeactivate: 'Account successfully deactivated',
  messageSuccessAccountActivate: 'Account successfully activated',
  messageErrorDeactivatingAccount: 'Account could not be deactivated',
  messageSuccessAccountCreation: 'Account successfully invited',
  messageErrorAccountCreation: 'Account was not created',
  messageSuccessAccountUpdate: 'Account was updated successfully',
  messageErrorAccountUpdate: 'Account could not be updated',
  messageSafetyCheck: 'Patient can be safely treated',
  messageSafetyCheckWithRemarks:
    'Remarks must be added before treating the patient',
  contactMessageSuccessfullySent: 'Message sent. Thanks for your feedback.',
  contactMessageNotSent: 'There was an error sending your message',
  patientIdAlreadyExists: 'This Internal Data Patient ID is already in use',
  fieldEmpty: 'Added questionnaire is empty',
} as const;
